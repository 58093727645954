import React from 'react'
import BannerLocationDetails from './cards/BannerLocationDetails'

export function AddBannerLocation() {
  return (
    <>
      <BannerLocationDetails  />
    </>
  )
}
