
import AuthorDetails from './cards/AuthorDetails'

export function AddAuthor() {
  return (
    <>
      <AuthorDetails  />
    </>
  )
}
