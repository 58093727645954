import React from 'react'
import GenreDetails from './cards/GenreDetails'

export function AddGenre() {
  return (
    <>
      <GenreDetails  />
    </>
  )
}
