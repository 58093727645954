
import BookCollectionDetails from './cards/BookCollectionDetails'

export function AddBookCollection() {
  return (
    <>
      <BookCollectionDetails  />
    </>
  )
}
