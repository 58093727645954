
import AdminUserDetails from './cards/AdminUserDetails'

export function AddAdminUser() {
  return (
    <>
      <AdminUserDetails  />
    </>
  )
}
