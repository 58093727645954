/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_ejaz/helpers'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../_ejaz/partials'
import {useLocation} from 'react-router'

const BooksHeader: React.FC = () => {
  const location = useLocation()

  return (
    <div>
      
    </div>
  )
}

export {BooksHeader}
