
import GroupDetails from './cards/GroupDetails'

export function AddGroup() {
  return (
    <>
      <GroupDetails  />
    </>
  )
}
