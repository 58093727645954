
import BannerDetails from './cards/BannerDetails'

export function AddBanner() {
  return (
    <>
      <BannerDetails  />
    </>
  )
}
