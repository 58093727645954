
import PublisherDetails from './cards/PublisherDetails'

export function AddPublisher() {
  return (
    <>
      <PublisherDetails  />
    </>
  )
}
