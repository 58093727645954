import React from 'react'
import TagDetails from './cards/TagDetails'

export function AddTag() {
  return (
    <>
      <TagDetails  />
    </>
  )
}
