import React from 'react'
import ThematicAreaDetails from './cards/ThematicAreaDetails'

export function AddThematicArea() {
  return (
    <>
      <ThematicAreaDetails  />
    </>
  )
}
