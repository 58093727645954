
import CategoryDetails from './cards/CategoryDetails'

export function AddCategory() {
  return (
    <>
      <CategoryDetails  />
    </>
  )
}
