import React from 'react'
import PaymentMethodDetails from './cards/PaymentMethodDetails'

export function AddPaymentMethod() {
  return (
    <>
      <PaymentMethodDetails  />
    </>
  )
}
