
import SubscriptionDetails from './cards/SubscriptionDetails'

export function AddSubscription() {
  return (
    <>
      <SubscriptionDetails  />
    </>
  )
}
