/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useLocation} from 'react-router'

const AdminUsersHeader: React.FC = () => {
  const location = useLocation()

  return (
    <div>
      
    </div>
  )
}

export {AdminUsersHeader}
