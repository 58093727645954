
import PaymentDetails from './cards/PaymentDetails'

export function AddPayment() {
  return (
    <>
      <PaymentDetails  />
    </>
  )
}
