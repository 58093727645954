
import TrialUserDetails from './cards/TrialUserDetails'

export function AddTrialUser() {
  return (
    <>
      <TrialUserDetails  />
    </>
  )
}
