
import SuggestBookDetails from './cards/SuggestBookDetails'


export function AddBook() {
  return (
    <>
      <SuggestBookDetails  />
    </>
  )
}
