import { AuthorFormValues } from "./author";
import { ItemFormValues } from "./item";
import { PublisherFormValues } from "./publisher";

export interface SuggestBook {
    bk_ID: string | undefined;
    bk_Code: string
    bk_Name: string | null;
    bk_Name_Ar: string | null;
    bk_Title_Ar: string | null;
    bk_Active: boolean
    bk_Trial: string | null;
    bk_Title: string
    bk_Language: string
    bk_Author: string
    bk_Editor: string
    bk_Comments: string
    bk_CreatedOn: string
    bk_Creator: string | null;
    bk_Modifier :string;
    publishers : PublisherFormValues[]
    publisherItems : ItemFormValues[]
    authors : AuthorFormValues[]
    authorItems : ItemFormValues[]

  }

  export class SuggestBookFormValues 
  {
    bk_ID: string | undefined = undefined;
    bk_Code: string = "";
    bk_Name: string | null = "";
    bk_Name_Ar: string | null = "";
    bk_Title_Ar: string | null = "";
    bk_Active: boolean = false;
    bk_Trial: string | null = "";
    bk_Title: string = "";
    bk_Language: string = "";
    bk_Author: string = "";
    bk_Editor: string = "";
    bk_Comments: string = "";
    bk_CreatedOn: string = "";
    bk_Creator: string | null = "";
    bk_Modifier: string = "";
    publishers : PublisherFormValues[] = []
    publisherItems : ItemFormValues[] = []
    authors : AuthorFormValues[] = []
    authorItems : ItemFormValues[] = [] 

    constructor(suggestBook?: SuggestBookFormValues){
        if(suggestBook){
            this.bk_ID = suggestBook.bk_ID;
            this.bk_Code = suggestBook.bk_Code;
            this.bk_Name = suggestBook.bk_Name;
            this.bk_Name_Ar = suggestBook.bk_Name_Ar;
            this.bk_Title = suggestBook.bk_Title;
            this.bk_Title_Ar = suggestBook.bk_Title_Ar;
            this.bk_Active = suggestBook.bk_Active;
            this.bk_Trial = suggestBook.bk_Trial;
            this.bk_Language = suggestBook.bk_Language;
            this.bk_Author = suggestBook.bk_Author;
            this.bk_Editor = suggestBook.bk_Editor;
            this.bk_Comments = suggestBook.bk_Comments;
            this.bk_CreatedOn = suggestBook.bk_CreatedOn;
            this.bk_Creator = suggestBook.bk_Creator
            this.bk_Modifier = suggestBook.bk_Modifier;
            this.publishers = suggestBook.publishers;
            this.publisherItems  = suggestBook.publisherItems;
            this.authors = suggestBook.authors;
            this.authorItems  = suggestBook.authorItems;
        }
    }
  }

  export class SuggestBook implements SuggestBook {
    constructor(init?: SuggestBookFormValues){
        Object.assign(this,init);
    }
  }