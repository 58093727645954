import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik} from 'formik'
import { useNavigate } from 'react-router-dom'
import { BookFormValues } from '../../../../../models/book'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../../stores/store'
import LoadingComponent from '../../../../../layout/Common/LoadingComponent'
import EjazTextInput from '../../../../../layout/Common/EjazTextInput'
import EjazTextArea from '../../../../../layout/Common/EjazTextArea'
import BookProperties from '../../Common/SuggestBookProperties'
import EjazCheckboxInput from '../../../../../layout/Common/EjazCheckboxInput'
import Multiselect from 'multiselect-react-dropdown'
import PhotoUploadWidget from '../../../../../layout/Common/imageUpload/PhotoUploadWidget'
import { MediumFormValues } from '../../../../../models/medium'
import { SuggestBookFormValues } from '../../../../../models/suggestBook'
import agent from '../../../../../api/agent'

interface Props {
  id: string | undefined
}



export default observer(function SuggestBookDetails({id}: Props) {
  const { suggestbookStore, publisherStore, authorStore, categoryStore, genreStore, tagStore, thematicAreaStore, mediumStore } = useStore();
  const { updateBook, selectedBook, loadsuggestedBooks, uploadMedium, deleteMedium, deleteAudioEn, deleteAudioAr, setSrc, uploadAudioEn, setSrcAudioEn, uploadAudioAr, setSrcAudioAr, setDeleted, loadingInitial, uploading, uploaded, src, uploadingAudioEn, uploadedAudioEn, srcAudioEn, uploadingAudioAr, uploadedAudioAr, srcAudioAr, currentAudioEn, currentAudioAr } = suggestbookStore;
  const { loadPublishers, returnedPublishers } = publisherStore;
  const { loadAuthors, returnedAuthors } = authorStore;
  const { loadThematicAreas, returnedThematicAreas } = thematicAreaStore;
  const { loadCategories, returnedCategories} = categoryStore;
  const { loadGenres, returnedGenres } = genreStore;
  const { loadTags, returnedTags } = tagStore;
  const { loadMedium } = mediumStore;
  const navigate = useNavigate();
  const [bk_id, setBkId] = useState<string>('');

  const [book, setBook] = useState<SuggestBookFormValues>(new SuggestBookFormValues());
  const [medium, setMedium] = useState<MediumFormValues>(new MediumFormValues());

  const validationSchema = Yup.object().shape({
    bk_Code: Yup.string().required('Code required!').min(3, 'Minimum 3 characters required!'),
    bk_Name: Yup.string().required('Name required!').min(3, 'Minimum 3 characters required!'),
    bk_Name_Ar: Yup.string().required('Arabic Name required!').min(3, 'Minimum 3 characters required!'),
    bk_Title: Yup.string().required('Title required!').min(3, 'Minimum 3 characters required!'),
    bk_Title_Ar: Yup.string().required('Arabic Title required!').min(3, 'Minimum 3 characters required!')
  })



  useEffect(() => {
    loadAuthors();
  }, [loadAuthors])
  
  useEffect(() => {
    loadPublishers();
  }, [loadPublishers])

  useEffect(() => {
    loadCategories();
  }, [loadCategories])
  
  useEffect(() => {
    loadGenres();
  }, [loadGenres])

  useEffect(() => {
    loadTags();
  }, [loadTags])

  useEffect(() => {
    loadThematicAreas();
  }, [loadThematicAreas])


  useEffect(() => {
    setBkId(id || '')
  }, [id])

  function handleUploadMedium(file: Blob) {
    uploadMedium(file);
  }

  function handleDeleteMedium() {
    deleteMedium();
  }

  function handleDeleteAudioEn() {
    deleteAudioEn();
  }

  function handleDeleteAudioAr() {
    deleteAudioAr();
  }

  function handleAudioEnOnCancel() {
    setSrcAudioEn('');
    navigate(-1);
  }

  function handleUploadAudioEn(file: Blob) {
    uploadAudioEn(file);
  }

  function handleAudioArOnCancel() {
    setSrcAudioAr('');
    navigate(-1);
  }

  function handleUploadAudioAr(file: Blob) {
    uploadAudioAr(file);
  }

  function handleFormSubmit(book: SuggestBookFormValues) {
    updateBook(book).then(() => {
        navigate(`/suggestbook/view/${book.bk_ID}`)
    })
  }

  function handleFormCancel(book: BookFormValues) {
    setSrc('')
    setDeleted(0)
    navigate(-1)
  }


  function handleDeleteBook() {
    if (bk_id) {
      agent.SuggestBooks.delete(bk_id).then(() => {
        navigate('/suggestbooks/list'); // Redirect to wherever you want after delete
      }).catch(error => {
        console.error("Error deleting book:", error);
      });
    }
  }

  const publisherOptions = returnedPublishers.map(publisher => (
    {name: ((publisher.pb_Title == "NA" || publisher.pb_Title == "N/A") ? publisher.pb_Title_Ar : publisher.pb_Title), id: publisher.pb_ID}
  ))

  const authorOptions = returnedAuthors.map(author => (
    {name: ((author.at_Title == "NA" || author.at_Title == "N/A") ? author.at_Title_Ar : author.at_Title), id: author.at_ID}
  ))

  const categoryOptions = returnedCategories.map(category => (
    {name: category.ct_Title, id: category.ct_ID}
  ))

  const genreOptions = returnedGenres.map(genre => (
    {name: genre.gn_Title, id: genre.gn_ID}
  ))

  const tagOptions = returnedTags.map(tag => (
    {name: tag.tg_Title, id: tag.tg_ID}
  ))

  const thematicAreaOptions = returnedThematicAreas.map(thematicArea => (
    {name: thematicArea.th_Title, id: thematicArea.th_ID}
  ))
  
  const languageOptions =   [{name: 'All', id: 'All'}, {name: 'Arabic', id: 'Arabic'}, {name: 'English', id: 'English'}]

  const preselectedLanguageOptions = (book.bk_Language != null) ? 
    [{name: book.bk_Language, id: book.bk_Language}]
  : []
  


  const preselectedAuthorOptions = (book.bk_Author != null) ? book.bk_Author = '': 'NA';

  

  if (loadingInitial) return <LoadingComponent content='Loading...' />

  const mediumsrc = (src) ? src : `${medium.md_URL}`;

  return (
    <Formik
      enableReinitialize
      validationSchema = {validationSchema}
      initialValues={book}
      onSubmit={vals => handleFormSubmit(vals)}
    >
    {({ handleSubmit, isValid, isSubmitting, dirty, setFieldValue}) => (
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card mb-5 mb-xl-10'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#book_details'
            aria-expanded='true'
            aria-controls='book_details'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>Book Details</h3>
            </div>
          </div>

          <div id='book_details' className='collapse show'>
            <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>Cover</label>
              <div className='col-lg-10'>
              <PhotoUploadWidget  uploadPhoto={handleUploadMedium} deletePhoto={handleDeleteMedium} loading={uploading} uploaded={uploaded} src={mediumsrc} height={188} aspectRatio={0.67} />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-2 col-form-label fw-bold fs-6'>Audio</label>
              <div className='col-lg-10'>

              
              </div>
            </div>
            
            <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Code</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextInput placeholder={'Code'} name={'bk_Code'} />
                    </div>
                  </div>
                </div>
              </div>
            <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Name</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextInput placeholder={'Name'} name={'bk_Name'} />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextInput placeholder={'اسم'} name={'bk_Name_Ar'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Title</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextInput placeholder={'Title'} name={'bk_Title'} />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextInput placeholder={'عنوان'} name={'bk_Title_Ar'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Description</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'Description'} name={'bk_Desc'} rows={4} />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'وصف'} name={'bk_Desc_Ar'} rows={4} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Introduction</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'Introduction'} name={'bk_Introduction'} rows={4} />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'مقدمة'} name={'bk_Introduction_Ar'} rows={4} />
                    </div>
                  </div>
                </div>
              </div>
              
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Summary</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'Summary'} name={'bk_Summary'} rows={4} />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'ملخص'} name={'bk_Summary_Ar'} rows={4} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Characters</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'Characters'} name={'bk_Characters'} rows={4} />
                    </div>
                    <div className='col-lg-6 fv-row'>
                      <EjazTextArea placeholder={'الشخصيات'} name={'bk_Characters_Ar'} rows={4} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Language</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                    <Multiselect
                      options={languageOptions} 
                      displayValue="name" 
                      className='form-select form-select-solid'
                      placeholder='Language'
                      onSelect={(selectedlist, selectedItem) => setFieldValue("bk_Language", selectedItem.id)}
                      selectedValues={preselectedLanguageOptions}
                      singleSelect
                      />
                      <EjazTextInput type='hidden' placeholder='Language' name={'bk_Language'} value='All' />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Publishers</label>
 
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Authors</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                    <Multiselect
                      options={authorOptions} 
                      displayValue="name" 
                      className='form-select form-select-solid'
                      placeholder='Authors'
                      selectedValues={preselectedAuthorOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Thematic Areas</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                    <Multiselect
                      options={thematicAreaOptions} 
                      displayValue="name" 
                      className='form-select form-select-solid'
                      placeholder='Thematic Areas'
  
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Categories</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                    <Multiselect
                      options={categoryOptions} 
                      displayValue="name" 
                      className='form-select form-select-solid'
                      placeholder='Categories'
   
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Genres</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                    <Multiselect
                      options={genreOptions} 
                      displayValue="name" 
                      className='form-select form-select-solid'
 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Tags</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                    <Multiselect
                      options={tagOptions} 
                      displayValue="name" 
                      className='form-select form-select-solid'
                      placeholder='Tags'
   
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Trial</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazCheckboxInput label={'Enable'} name={'bk_Trial'} id={'Trial'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>Status</label>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-6 fv-row'>
                      <EjazCheckboxInput label={'Enable'} name={'bk_Active'} id={'Status'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button onClick={() => { setSrc(''); setDeleted(0); navigate(-1)}} className='btn btn-light align-self-center mx-10'>
                Cancel
              </button>
                <button type='button' onClick={handleDeleteBook} className='btn btn-danger'>
                Delete
              </button>
            </div>
          </div>
        </div>
        <BookProperties book={book} />
      </form>
    )} 
    </Formik>
  )
})

function deleteAudioEn() {
  throw new Error('Function not implemented.')
}

