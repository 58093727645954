
import BookDetails from './cards/BookDetails'

export function AddBook() {
  return (
    <>
      <BookDetails  />
    </>
  )
}
